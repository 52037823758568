var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"dense-inputs"},[_c('div',{staticClass:"col-sm-12 col-md-8 no-gutters"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex align-center pt-3 flex-md-wrap col-md-11 col-lg-11"},[_c('v-text-field',{staticClass:"filter-width-180 force-text-left",attrs:{"placeholder":_vm.$t('message.no'),"value":_vm.searchTerm,"clearable":"","dense":"","hide-details":"auto","append-icon":"search","solo":""},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-select',{staticClass:"filter-width-150 ml-2",attrs:{"items":_vm.warehouses,"loading":_vm.loading.filter.offices,"placeholder":_vm.$t('message.allWarehouses'),"autocomplete":"off","clearable":"","dense":"","hide-details":"","item-value":"Office.id","item-text":"Office.code","solo":""},on:{"change":function($event){return _vm.loadStockOrders()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Office.code)+" ")]}}]),model:{value:(_vm.filterOptions.office),callback:function ($$v) {_vm.$set(_vm.filterOptions, "office", $$v)},expression:"filterOptions.office"}}),_c('v-autocomplete',{staticClass:"filter-width-195 ml-2",attrs:{"items":_vm.stockOrderSuppliers,"loading":_vm.loading.filter.suppliers,"placeholder":_vm.$t('message.supplier'),"autocomplete":"off","clearable":"","dense":"","hide-details":"auto","item-value":"Supplier.id","item-text":"Supplier.title","solo":""},on:{"change":function($event){return _vm.loadStockOrders()}},model:{value:(_vm.filterOptions.supplier),callback:function ($$v) {_vm.$set(_vm.filterOptions, "supplier", $$v)},expression:"filterOptions.supplier"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"loading":_vm.loading.reset},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(_vm._s(_vm.$t('message.clear')))])],1),_c('v-spacer'),_c('ExportTableJson',{attrs:{"export-conditions":_vm.exportConditions,"export-data":_vm.StockOrders,"export-fields":_vm.headers,"export-source":'stockorders'}})],1)],1)]),_c('v-overlay',{attrs:{"value":_vm.loading.stockOrders,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table col-sm-12 col-md-8",attrs:{"footer-props":{
            itemsPerPageOptions: [10,20,50,-1],
            showCurrentPage: true,
            showFirstLastPage: true
        },"headers":_vm.headers,"items":_vm.StockOrders,"options":_vm.tableOptions,"search":_vm.searchTerm,"calculate-widths":"","dense":"","id":"stockOrderTable","item-key":"StockOrder.id"},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.StockOrder.title",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.StockOrder.title))])]}},{key:"item.StockOrder.Contract.title",fn:function(ref){
        var item = ref.item;
return [(item.StockOrder.Contract.title != null)?_c('span',[_vm._v(_vm._s(item.StockOrder.Contract.title))]):_vm._e(),(item.StockOrder.Contract.title == null)?_c('div',[(!_vm.$can('update','StockOrder'))?_c('span',[_vm._v(_vm._s(_vm.$t('message.na')))]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openLinkPurchaseOrderDialog(item.StockOrder.id, item.StockOrder.title, item.StockOrder.purchaseorder_id)}}},on),[_c('v-icon',[_vm._v("mdi mdi-link")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.linkOldPurchaseOrder')))])])],1):_vm._e()]}},{key:"item.StockOrder.stockindate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.StockOrder.stockindate))+" ")]}},{key:"item.StockOrder.Supplier.title",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.StockOrder.Supplier.otsname)+" ")]}},{key:"item.StockOrder.container",fn:function(ref){
        var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.formatContainers(item.StockOrder.container))}})]}},{key:"item.StockOrder.id",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center justify-center",staticStyle:{"height":"100%"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.$can('update','StockOrder'))?_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.updateStockOrder(item.StockOrder.id)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateStockPo')))],1):_vm._e(),(_vm.$can('delete','StockOrder'))?_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.cancelStockOrder(item.StockOrder.id, item.StockOrder.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.cancelStockArrival')))],1):_vm._e()],1)],1)],1)]}}])}),_c('SimpleAlert',{attrs:{"alert_message":_vm.dialogs.error_message,"dialog":_vm.dialogs.error},on:{"update:alert_message":function($event){return _vm.$set(_vm.dialogs, "error_message", $event)},"update:dialog":function($event){return _vm.$set(_vm.dialogs, "error", $event)},"dialog-closed":_vm.dialogClosed}}),_c('LinkPurchaseOrder',{attrs:{"dialog":_vm.linkPurchaseOrderDialog,"purchase-order-id":_vm.selectedPurchaseOrderId,"stock-order":_vm.selectedStockOrder,"stock-order-id":_vm.selectedStockOrderId,"warehouse-id":_vm.filterOptions.office,"update-mode":_vm.updateLink},on:{"update:dialog":function($event){_vm.linkPurchaseOrderDialog=$event},"dialog-closed":_vm.linkPurchaseOrderDialogClosed,"linking-done":_vm.purchaseOrderLinked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }