<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="StockOrdersOverview"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 px-3>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <StockOrdersListing></StockOrdersListing>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import ExportTable from "Components/Appic/ExportTable";
    import StockOrdersListing from "Components/Appic/StockOrdersListing";
    import {mapFields} from "vuex-map-fields";
    export default {
        name: "StockOrdersOverview",
        components: {StockOrdersListing, ExportTable},
        title: '',
        data() {
            return {
                loader: false
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
        },
        mounted() {
            this.$title = this.$t('message.titles.stockArrivals')
        }
    }
</script>

<style scoped>

</style>