<template>
    <div>
        <v-sheet class="dense-inputs">
            <div class="col-sm-12 col-md-8 no-gutters">
                <v-row no-gutters>
                    <div class="d-flex align-center pt-3 flex-md-wrap col-md-11 col-lg-11">
                        <v-text-field
                            :placeholder="$t('message.no')"
                            :value="searchTerm"
                            class="filter-width-180 force-text-left"
                            clearable
                            dense
                            hide-details="auto"
                            append-icon="search"
                            solo
                            @change="searchTerm = $event"
                        />
                        <v-select
                            :items="warehouses"
                            :loading="loading.filter.offices"
                            :placeholder="$t('message.allWarehouses')"
                            autocomplete="off"
                            class="filter-width-150 ml-2"
                            clearable
                            dense
                            hide-details
                            item-value="Office.id"
                            item-text="Office.code"
                            solo
                            v-model="filterOptions.office"
                            @change="loadStockOrders()"
                        >
                            <template v-slot:selection="{item}">
                                {{ item.Office.code }}
                            </template>
                        </v-select>
                        <v-autocomplete
                            :items="stockOrderSuppliers"
                            :loading="loading.filter.suppliers"
                            :placeholder="$t('message.supplier')"
                            autocomplete="off"
                            class="filter-width-195 ml-2"
                            clearable
                            dense
                            hide-details="auto"
                            item-value="Supplier.id"
                            item-text="Supplier.title"
                            solo
                            v-model="filterOptions.supplier"
                            @change="loadStockOrders()"
                        />
                        <v-btn
                            :loading="loading.reset"
                            class="ml-2"
                            @click="resetFilters()"
                        >{{ $t('message.clear') }}</v-btn>
                    </div>
                    <v-spacer></v-spacer>
                    <ExportTableJson
                        :export-conditions="exportConditions"
                        :export-data="StockOrders"
                        :export-fields="headers"
                        :export-source="'stockorders'"
                    />
                </v-row>
            </div>
        </v-sheet>
        <v-overlay
            :value="loading.stockOrders"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular color="primary" indeterminate size="40" width="6"/>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [10,20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :items="StockOrders"
            :options.sync="tableOptions"
            :search="searchTerm"
            calculate-widths
            class="mt-0 appic-table-light specification-table col-sm-12 col-md-8"
            dense
            id="stockOrderTable"
            item-key="StockOrder.id"
        >
            <template v-slot:item.StockOrder.title="{item}">
                <span class="font-weight-bold">{{ item.StockOrder.title }}</span>
            </template>
            <template v-slot:item.StockOrder.Contract.title="{item}">
                <span v-if="item.StockOrder.Contract.title != null">{{ item.StockOrder.Contract.title }}</span>
                <div v-if="item.StockOrder.Contract.title == null">
                    <span v-if="!$can('update','StockOrder')">{{$t('message.na') }}</span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                small
                                icon
                                @click="openLinkPurchaseOrderDialog(item.StockOrder.id, item.StockOrder.title, item.StockOrder.purchaseorder_id)"
                                v-on="on"
                            >
                                <v-icon>mdi mdi-link</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.linkOldPurchaseOrder') }}</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:item.StockOrder.stockindate="{item}">
                {{ formatDate(item.StockOrder.stockindate) }}
            </template>
            <template v-slot:item.StockOrder.Supplier.title="{item}">
                {{ item.StockOrder.Supplier.otsname }}
            </template>
            <template v-slot:item.StockOrder.container="{item}">
                <div v-html="formatContainers(item.StockOrder.container)"/>
            </template>
            <template v-slot:item.StockOrder.id="{ item }">
                <div  class="d-flex flex-row align-center justify-center" style="height: 100%">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="updateStockOrder(item.StockOrder.id)" v-if="$can('update','StockOrder')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateStockPo') }}</v-list-item>
                            <v-list-item class="font-sm" @click="cancelStockOrder(item.StockOrder.id, item.StockOrder.title)" v-if="$can('delete','StockOrder')"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelStockArrival') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        ></SimpleAlert>
        <LinkPurchaseOrder
            :dialog.sync="linkPurchaseOrderDialog"
            :purchase-order-id="selectedPurchaseOrderId"
            :stock-order="selectedStockOrder"
            :stock-order-id="selectedStockOrderId"
            :warehouse-id="filterOptions.office"
            :update-mode="updateLink"
            @dialog-closed="linkPurchaseOrderDialogClosed"
            @linking-done="purchaseOrderLinked"
        ></LinkPurchaseOrder>
    </div>
</template>

<script>
import {formatDate, log, numberFormat} from "Helpers/helpers";
import {mapFields, mapMultiRowFields} from "vuex-map-fields";
// import SimpleAlert from "./SimpleAlert";
import {mapGetters, mapActions} from "vuex";

const SimpleAlert = () => import("./SimpleAlert");
const LinkPurchaseOrder = () => import("Components/Appic/LinkPurchaseOrder");
const ExportTableJson = () => import("Components/Appic/ExportTableJson");

export default {
    name: "StockOrdersListing",
    components: {LinkPurchaseOrder, ExportTableJson, SimpleAlert},
    data(){
        return {
            dialogs: {
                error:  false,
                error_message: ""
            },
            exportConditions: {},
            filterOptions: {
                month: null,
                office: null,
                supplier: null,
                year: null,
            },
            linkPurchaseOrderDialog: false,
            loading: {
                fetch: false,
                filter: {
                    months: false,
                    offices: false,
                    suppliers: null,
                    years: false,
                },
                filterResults: false,
                stockOrders: false
            },
            StockOrders: [],
            searchField: null,
            searchTerm: null,
            selectedPurchaseOrderId: null,
            selectedStockOrder: null,
            selectedStockOrderId: null,
            tableOptions: {
                page: 1
            },
            totalStockOrders: 0,
            updateLink: false
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('user',{
            User: 'User'
        }),
        ...mapFields('stockorder',{
            stockOrderSuppliers: 'current.stockOrderSuppliers',
        }),
        ...mapGetters([
            'currencies',
            'months',
            'offices'
        ]),
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'StockOrder.id',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 2,
                    text: this.$t('message.number'),
                    value: 'StockOrder.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false
                },
                {
                    id: 3,
                    text: this.$t('message.wh'),
                    value: 'StockOrder.Office.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false
                },
                {
                    id: 4,
                    text: this.$t('message.etaWh'),
                    value: 'StockOrder.stockindate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 5,
                    text: this.$t('message.supplier'),
                    value: 'StockOrder.Supplier.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 6,
                    text: this.$t('message.supplierInvoice'),
                    value: 'StockOrder.invno',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 7,
                    text: this.$t('message.quantity'),
                    value: 'StockOrder.volume',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false
                },
                {
                    id: 8,
                    text: this.$t('message.containers'),
                    value: 'StockOrder.container',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false
                }
            ]
        },
        searchFields() {
            return this.headers.filter((header)=>header.searchable === true)
        },
        warehouses() {
            return this.offices.filter(o => [1,21].includes(o.Office.id))
        },
        years() {
            let array = []
            let currentYear = new Date().getFullYear()
            let startYear = currentYear - 7
            while(currentYear > startYear){
                array.push({Year: {text: currentYear.toString(), id: currentYear}})
                currentYear = currentYear - 1
            }
            return array
        }
    },
    methods: {
        ...mapActions('stockorder',{
            cancelStockOrderById: 'cancelStockOrderById',
            getAllStockOrders: 'getAllStockOrders',
            getAllStockOrderSuppliers: 'getAllStockOrderSuppliers'
        }),
        async cancelStockOrder(val, title){
            if(await this.$root.$confirm(this.$t('message.cancelStockArrival') + ' ' + title, this.$t('message.confirmations.continueStockArrivalCancelAction'), {color: 'orange'})){
                this.cancelStockOrderById(val)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.stockArrivalDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loadPurchaseOrders()
                        } else {
                            this.$toast.error(this.$t('message.errors.stockArrivalNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.stockArrivalNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        findOffice (val) {
            return this.offices.find(o => o.Office.id == val)
        },
        formatContainers(containerStr){
            let containers = ''
            if(typeof containerStr == 'string' && containerStr.length > 0){
                containers = containerStr.replace(/(?:\r\n|\r|\n)/g, "<br>")
            }
            return containers
        },
        formatDate,
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
        linkPurchaseOrderDialogClosed() {
            this.linkPurchaseOrderDialog = false;
        },
        loadStockOrders () {
            this.loading.stockOrders = true;
            let payload = {
                filterOptions: this.filterOptions
            }
            this.getAllStockOrders( payload )
                .then( (tableData) => {
                    this.StockOrders = tableData.rows
                    this.totalStockOrders = tableData.totalRows
                })
                .catch(() => {
                    this.loading.stockOrders = false
                    this.loading.fetch = false;
                })
                .finally(() => {
                    this.loading.stockOrders = false
                    this.loading.fetch = false
                    this.tableOptions.page = 1
                })
        },
        newStockArrival(){
            let tab = window.open('/v1/stockorders/add','_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        openLinkPurchaseOrderDialog(stockOrderId, stockOrder, purchaseOrderId) {
            this.linkPurchaseOrderDialog = true
            this.selectedStockOrderId = stockOrderId
            this.selectedStockOrder = stockOrder
            this.selectedPurchaseOrderId = purchaseOrderId
            if(purchaseOrderId != null) {
                this.updateLink = true
            } else {
                this.updateLink = false
            }
        },
        purchaseOrderLinked(stockOrderObj) {
            const stockOrderId = stockOrderObj.stockOrderId
            const purchaseOrder = stockOrderObj.linkedPurchaseOrder
            const purchaseOrderId = stockOrderObj.linkedPurchaseOrderId
            const index = this.StockOrders.findIndex(stockOrder => stockOrder.StockOrder.id == stockOrderId)
            log(index)
            if(index){
                this.StockOrders[index]['StockOrder']['Contract']['title'] = purchaseOrder
                this.StockOrders[index]['StockOrder']['purchaseorder_id'] = purchaseOrderId
            }
            this.linkPurchaseOrderDialog = false;
        },
        resetFilters () {
            this.filterOptions.office = null
            this.filterOptions.month = null
            this.filterOptions.supplier = null
            this.filterOptions.year = null
            this.loadStockOrders()
        },
        updateStockOrder (val){
            let tab = window.open('/v1/stockorders/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
    },
    created() {
        // let d = new Date()
        // this.filterOptions.year = d.getFullYear()
        if(this.stockOrderSuppliers.length == 0) this.getAllStockOrderSuppliers()
        // this.getAllStockOrderSuppliers()
        this.loadStockOrders()
        if(this.User){
            if(this.User.role == 'ROLE_SALES_ADMIN'){
                switch(this.User.country_id){
                    case 4:
                        this.filterOptions.office = 21
                        break
                    case 1:
                    default:
                        this.filterOptions.office = 1
                        break
                }
            } else {
                this.filterOptions.office = null
            }
        } else {
            this.filterOptions.office = 1
        }
    }
}
</script>

<style>
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.filter-width-180 {
    width: 180px !important;
    max-width: 180px !important;
}
.filter-width-195 {
    width: 195px !important;
    max-width: 195px !important;
}
.border-top-1 {
    border-top: 1px solid lightgray !important;
}
.v-dialog {
    min-width: 200px !important;
}
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>